import { createContext, useContext, useEffect, useState } from 'react';

import { WrapperProps } from '@reece/global-types';
import { noop } from 'lodash-es';

import { useAuthContext } from 'AuthProvider';
import { useApiUseEmbeddedSession } from 'API/embedded.api';
import { EmbeddedSessionResponse } from 'API/types/embedded.types';
import { ErpSystemEnum } from 'generated/graphql';
import { useQueryParams } from 'hooks/useSearchParam';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { embedPage, embedding } from 'utils/embedPage';
import { useHistory, useLocation } from 'react-router-dom';
import { getSupportedRoute } from 'pages/Embedded/supportedRoutes';

/**
 * Types
 */
export type EmbeddedContextType = {
  embeddedSession: EmbeddedSessionResponse;
  embeddedSessionLoading: boolean;
  embeddedSessionRefetch: () => void;
};
export type EmbeddedParams = {
  embeddedSessionId?: string;
  deviceId?: string;
};

/**
 * Initializer
 */
const embeddedSessionInitializer: EmbeddedSessionResponse = {
  deviceId: '',
  oktaToken: '',
  jobAccountUUID: '',
  billToUUID: '',
  jobAccountId: '',
  billToId: '',
  erpName: ErpSystemEnum.Eclipse,
  branchId: '',
  path: '',
  extraData: null
};
/**
 * Context
 */
export const defaultEmbeddedContext: EmbeddedContextType = {
  embeddedSession: embeddedSessionInitializer,
  embeddedSessionLoading: false,
  embeddedSessionRefetch: noop
};
export const EmbeddedContext = createContext(defaultEmbeddedContext);
export const useEmbeddedContext = () => useContext(EmbeddedContext);

/**
 * Provider
 */
function EmbeddedProvider({ children }: WrapperProps) {
  /**
   * Hooks
   */
  const history = useHistory();
  const { pathname, search } = useLocation();

  /**
   * Context
   */
  const { updateAccounts } = useSelectedAccountsContext();
  const { setEmbeddedSessionResponse } = useAuthContext();
  const [queryParams] = useQueryParams<EmbeddedParams>();

  /**
   * States
   */
  const [embeddedSession, setEmbeddedSession] =
    useState<EmbeddedSessionResponse>(embeddedSessionInitializer);
  const [getEmbeddedSessionFailed, setGetEmbeddedSessionFailed] =
    useState<boolean>(false);

  /**
   * API
   */
  // 🟣 API - GET
  const { loading: embeddedSessionLoading, refetch: embeddedSessionRefetch } =
    useApiUseEmbeddedSession(
      {
        onCompleted: ({ data }) => handleCompleteUseEmbeddedSession(data),
        // istanbul ignore next
        onError: () => setGetEmbeddedSessionFailed(true),
        skip: !embeddedSession?.billToId
      },
      {
        embeddedSessionId: queryParams.embeddedSessionId ?? '',
        deviceId: queryParams.deviceId ?? ''
      }
    );

  // 🟤 handle request that obtains the EmbeddedSession
  const handleCompleteUseEmbeddedSession = (
    embeddedSession: EmbeddedSessionResponse
  ) => {
    setEmbeddedSession(embeddedSession);
    updateAccounts(
      {
        branchId: embeddedSession.branchId,
        erpAccountId: embeddedSession.jobAccountId,
        erpSystemName: embeddedSession.erpName,
        id: embeddedSession.jobAccountUUID
      },
      {
        branchId: embeddedSession.branchId,
        erpAccountId: embeddedSession.billToId,
        erpSystemName: embeddedSession.erpName,
        id: embeddedSession.billToUUID
      }
    );
    embedPage(embeddedSession);
    setEmbeddedSessionResponse?.(embeddedSession);
  };

  /**
   * useEffect's
   */
  // istanbul ignore next
  useEffect(() => {
    if (
      !getEmbeddedSessionFailed &&
      embedding() &&
      !embeddedSession?.oktaToken &&
      !embeddedSessionLoading &&
      queryParams.embeddedSessionId &&
      queryParams.deviceId
    ) {
      embeddedSessionRefetch();
    }
  }, [
    embeddedSession,
    embeddedSessionRefetch,
    embeddedSessionLoading,
    queryParams,
    getEmbeddedSessionFailed
  ]);

  useEffect(() => {
    if (!embedding()) {
      return;
    }
    // if no session ID in the URL, create one
    if (
      pathname.includes('/embedded') &&
      !search.includes('embeddedSessionId')
    ) {
      const path = pathname.replace('/embedded', ''); // get path from URL
      const supportedRoute = getSupportedRoute(path); // check if route is supported
      if (supportedRoute?.path) {
        sessionStorage.setItem('embedded', 'true');
        history.push(path);
      }
    }
  }, [pathname, search, history]);

  /**
   * Render
   */
  return (
    <EmbeddedContext.Provider
      value={{
        embeddedSession,
        embeddedSessionLoading,
        embeddedSessionRefetch
      }}
    >
      {children}
    </EmbeddedContext.Provider>
  );
}

export default EmbeddedProvider;
