import { APIOptions } from 'API/hooks/useApiBase';
import { UseAPILazyProps } from 'API/hooks/useApiLazy';
import { useApiLazyAuth } from 'API/hooks/useApiLazyAuth';
import {
  RapidDeliveryRequest,
  RapidDeliveryResponse
} from 'API/types/checkout.types';

// 🟩 POST /account/rapidDeliveryCheck - Check Rapid Delivery availability for checkout
export function useApiRapidDeliveryCheck(
  options: APIOptions<RapidDeliveryResponse> = {}
) {
  const props: UseAPILazyProps<RapidDeliveryResponse> = {
    url: '',
    kind: 'post',
    options,
    header: {}
  };
  const api = useApiLazyAuth<RapidDeliveryResponse>(props);

  const call = async (cartId: string, body: RapidDeliveryRequest) =>
    await api.call({
      ...props,
      url: `account/rapidDeliveryCheck/${cartId}`,
      body
    });
  return { ...api, call };
}
