import { useState } from 'react';

import { FormikProps } from 'formik';
import { noop } from 'lodash-es';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { List } from 'API/types/lists.types';
import { Button, CircularProgress, DropdownButton } from 'components';
import {
  Delete,
  DownloadIcon,
  DuplicateIcon,
  EditIcon,
  FileUploadIcon,
  MoreActions,
  PrintIcon
} from 'icons';
import ListFormDialog from 'pages/Lists/sub/ListFormDialog';
import ListDeleteDialog from 'pages/Lists/sub/ListDeleteDialog';
import { ListNaming } from 'pages/Lists/provider/hooks/useListActionsForm';
import {
  ListAction,
  useListsPageContext
} from 'pages/Lists/provider/ListsPageProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Types
 */
export type ListMoreActionsProps = {
  index?: number;
  horizontal?: number;
  showText?: boolean;
  list?: List;
};

/**
 * Component
 */
function ListMoreActions(props: ListMoreActionsProps) {
  /**
   * Props
   */
  const { horizontal = 140, showText, list } = props;

  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { push } = useHistory();
  const { toast } = useToastContext();
  const {
    callDeleteList,
    callDownloadListCSV,
    callDuplicateList,
    callPrintList,
    callUpdateList,
    deleteListLoading,
    duplicateListLoading,
    setFileLoading,
    updateListLoading
  } = useListsPageContext();
  const { selectedAccounts } = useSelectedAccountsContext();

  /**
   * State
   */
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [formikInstance, setFormikInstance] =
    useState<FormikProps<ListNaming>>();
  const [currentCallback, setCurrentCallback] = useState<
    (values: ListNaming) => void
  >(() => noop);
  const [selectedAction, setSelectedAction] = useState<ListAction>();
  const [isDeleting, setIsDeleting] = useState(false);

  /**
   * Callbacks
   */
  // 🟤 Cb - Menu item - Download List
  const handleDownloadList = (setOpen: (open: boolean) => void) => () => {
    setFileLoading(true);
    setOpen(false);
    callDownloadListCSV(list?.id ?? '', { listName: list?.name ?? '' })
      .catch(() => toast({ message: t('lists.networkError'), kind: 'error' }))
      .finally(() => setFileLoading(false));
  };

  // 🟤 Cb - Menu item - Print List
  const handlePrintList = (setOpen: (open: boolean) => void) => () => {
    setFileLoading(true);
    const name = list?.name.trim() ?? '';
    const listId = list?.id ?? '';
    const res = callPrintList(listId, {
      listName: name,
      billToId: selectedAccounts.billTo?.id ?? '',
      companyName: selectedAccounts.billToErpAccount?.companyName ?? ''
    });
    setOpen(false);
    res
      .then((response) =>
        showResultMessage(response?.status, name, ListAction.print)
      )
      .catch(catchNetworkError)
      .finally(() => setFileLoading(false));
  };

  // 🟤 Cb - Close actions menu and optionally open modal
  const handleUploadList = () => list && push(`/lists/upload/${list.id}`);

  // 🟤 Cb - Open Form dialog
  const handleOpenFormDialog =
    (setOpen: (open: boolean) => void, type: ListAction) => () => {
      setOpen(false);
      setOpenFormDialog(true);
      setSelectedAction(type);
    };

  // 🟤 Cb - Open Delete dialog
  const handleOpenDeleteDialog = (setOpen: (open: boolean) => void) => () => {
    setOpen(false);
    setOpenDeleteDialog(true);
  };

  // 🟤 Cb - Make rename list call
  const renameList = (values: ListNaming) => {
    if (!list?.id) {
      return;
    }
    // Remove whitespace
    const name = values.name.trim();
    const description = values.description.trim();
    callUpdateList(list.id, { name, description })
      .then((res) => {
        res === 200 && formikInstance?.resetForm();
        showResultMessage(res, name, ListAction.update);
      })
      .catch(catchNetworkError);
  };

  // 🟤 Cb - Make duplicate list call
  const duplicateList = (values: ListNaming) => {
    if (!list?.id) {
      return;
    }
    // Remove whitespace
    const name = values.name.trim();
    const description = values.description.trim();
    callDuplicateList(list.id, { name, description })
      .then((res) => {
        formikInstance?.resetForm();
        showResultMessage(res, name, ListAction.duplicate);
      })
      .catch(catchNetworkError);
  };

  // 🟤 Cb - Make submit call
  const handleFormSubmit = (callback: (values: ListNaming) => void) => () => {
    setCurrentCallback(() => callback);
    formikInstance?.submitForm();
  };

  // 🟤 Cb - Close delete list dialog
  const closeDeleteDialog = () => setOpenDeleteDialog(false);

  // 🟤 Cb - Make delete list call
  const deleteList = () => {
    setIsDeleting(true);
    callDeleteList(list?.id ?? '')
      .then(() => {
        const message = t('lists.removeListAction', { name: list?.name });
        toast({ message, kind: 'success' });
      })
      .catch(catchNetworkError)
      .finally(() => {
        setTimeout(
          // istanbul ignore next
          () => setIsDeleting(false),
          200
        );
        closeDeleteDialog();
      });
  };

  // 🟤 Cb - Show error or sucess message
  const showResultMessage = (
    response: number | undefined,
    name: string,
    change: ListAction
  ) => {
    const ok = response === 200;
    toast({
      message: t(ok ? 'lists.actionSuccess' : 'lists.actionFail', {
        name,
        change
      }),
      kind: ok ? 'success' : 'error'
    });
  };
  // 🟤 Cb - Show error or sucess message
  const catchNetworkError = () =>
    toast({ message: t('lists.networkError'), kind: 'error' });

  // 🟤 Cb - Close Form dialog
  const handleCloseDialog = () => {
    setOpenFormDialog(false);
    formikInstance?.resetForm();
    formikInstance?.setValues({
      name: list?.name ?? '',
      description: list?.description ?? ''
    });
  };

  /**
   * Render
   */
  return (
    <span className="flex items-center">
      <DropdownButton
        testId={`list-more-actions-${props.index ?? 'main-area'}`}
        className="!px-2 !min-w-0 whitespace-nowrap"
        content={(_, setOpen) => (
          <>
            <MenuItem onClick={handleDownloadList(setOpen)}>
              <Button
                data-testid="list-more-actions-download-button"
                iconStart={<DownloadIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.downloadList')}
              </Button>
            </MenuItem>
            <MenuItem onClick={handleUploadList}>
              <Button
                data-testid="list-more-actions-upload-button"
                iconStart={<FileUploadIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.uploadToList')}
              </Button>
            </MenuItem>
            <MenuItem
              onClick={handleOpenFormDialog(setOpen, ListAction.duplicate)}
            >
              <Button
                data-testid="list-more-actions-duplicate-button"
                iconStart={<DuplicateIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.duplicateList')}
              </Button>
            </MenuItem>
            <MenuItem
              onClick={handleOpenFormDialog(setOpen, ListAction.update)}
            >
              <Button
                data-testid="list-more-actions-rename-button"
                iconStart={<EditIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.renameList')}
              </Button>
            </MenuItem>
            <MenuItem
              disabled={!list?.listLineItemsSize}
              onClick={handlePrintList(setOpen)}
            >
              <Button
                data-testid="list-more-actions-print-button"
                iconStart={<PrintIcon />}
                color="gray"
                kind="text"
                size="sm"
              >
                {t('lists.printList')}
              </Button>
            </MenuItem>
            <MenuItem onClick={handleOpenDeleteDialog(setOpen)}>
              <Button
                data-testid="list-more-actions-delete-button"
                iconStart={<Delete />}
                color="red"
                kind="text"
                size="sm"
              >
                {t('lists.deleteList')}
              </Button>
            </MenuItem>
          </>
        )}
        transformOrigin={{ vertical: 'top', horizontal }}
        hideLastIcon
      >
        {showText && (
          <span className="mr-1 font-medium text-base">
            {t('lists.moreListActions')}
          </span>
        )}
        <MoreActions className="!w-6 !h-6" />
      </DropdownButton>
      <ListFormDialog
        onSubmitCallback={currentCallback}
        setFormikInstance={setFormikInstance}
        open={openFormDialog}
        onClose={handleCloseDialog}
        loading={duplicateListLoading || updateListLoading}
        title={(() => {
          switch (selectedAction) {
            case ListAction.duplicate:
              return t('lists.duplicateListTitle', { name: list?.name });
            default:
              return t('lists.renameList', { name: list?.name });
          }
        })()}
        initialValues={{
          name: list?.name ?? '',
          description: list?.description ?? ''
        }}
        actionType={selectedAction}
      >
        <Button
          type="submit"
          key="list-dialog-form-button"
          data-testid="list-dialog-form-button"
          className="flex-1 w-full"
          disabled={duplicateListLoading || updateListLoading}
          onClick={handleFormSubmit(
            selectedAction === ListAction.duplicate ? duplicateList : renameList
          )}
        >
          {duplicateListLoading || updateListLoading ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            t(
              selectedAction === ListAction.duplicate
                ? 'common.duplicate'
                : 'common.saveChanges'
            )
          )}
        </Button>
      </ListFormDialog>
      <ListDeleteDialog
        open={openDeleteDialog}
        title={t('lists.deleteListConfirmation', { name: list?.name })}
        loading={deleteListLoading || isDeleting}
        deleteButtonLabel={t('common.delete')}
        description={t('lists.deleteListNoNameConfirmation')}
        onClick={deleteList}
        onClose={closeDeleteDialog}
      />
    </span>
  );
}

export default ListMoreActions;
