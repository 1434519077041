import { useMemo, useRef, useState } from 'react';

import { Collapse } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import { isUndefined } from 'lodash-es';
import { Trans, useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import useResizeObserver from 'use-resize-observer';
import * as Yup from 'yup';
import { Maybe } from 'yup/lib/types';

import { useApiCreateList, useApiGetLists } from 'API/lists.api';
import { GetListsResponse, List } from 'API/types/lists.types';
import ListDrawerItem from 'common/ListsDrawer/sub/ListsDrawerItem';
import { Alert, Button, IconButton, Loader, TextInput } from 'components';
import { AddIcon, DeleteIcon, SearchIcon, WarningIcon } from 'icons';
import baseI18nComponents from 'locales/baseComponents';
import { acceptedCharPattern } from 'pages/Lists/provider/hooks/useListActionsForm';
import {
  MAX_LIST_ITEMS,
  SelectedListsMap,
  useListsContext
} from 'providers/ListsProvider';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import { useToastContext } from 'providers/ToastProvider';

/**
 * Config
 */
const ITEM_SIZE = 48;
const MAX_LIST_HEIGHT = 631;
const BOTTOM_HEIGHT = 240;

/**
 * Types
 */

type CreateListForm = { name: string };

/**
 * Component
 */
function AddToListDrawer() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();

  /**
   * Refs
   */
  const containerRef = useRef<HTMLDivElement>(null);
  const topSectionRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<FixedSizeList<List[]>>(null);
  const { height: containerH = 0 } = useResizeObserver({ ref: containerRef });
  const { height: topH = 0 } = useResizeObserver({ ref: topSectionRef });
  const listHeight = Math.min(
    MAX_LIST_HEIGHT,
    Math.max(48, containerH - (topH + BOTTOM_HEIGHT + 16))
  );

  /**
   * Context
   */
  const {
    addProductLoading,
    addProductToLists,
    removeProductFromLists,
    selectedAssociatedLists,
    selectedLists,
    setAddDrawerOpen,
    setSavedSelectedLists,
    setSelectedLists
  } = useListsContext();
  const { selectedAccounts } = useSelectedAccountsContext();
  const { toast } = useToastContext();

  /**
   * State
   */
  const [lists, setLists] = useState<List[]>([]);
  const [search, setSearch] = useState('');
  const [isCreatingList, setIsCreatingList] = useState(false);
  const [originalSelectedLists, setOriginalSelectedLists] =
    useState<SelectedListsMap>({});

  /**
   * API
   */
  // 🟣 API - get all lists
  const { loading: listsLoading } = useApiGetLists({
    onCompleted: onApiCompleteListData(false),
    skip: !selectedAccounts.billTo?.id
  });
  // 🟣 Lazy API - create new list
  const { loading: createListLoading, call: createListCall } = useApiCreateList(
    { onCompleted: onApiCompleteListData(true) }
  );

  /**
   * Memo
   */
  // 🔵 memo - list options
  const filteredLists = useMemo(
    () => lists.filter(({ name }) => new RegExp(search, 'i').test(name)),
    [lists, search]
  );
  // 🔵 memo - find if list has more than 600 limit
  const hasSelectedFullList = useMemo(
    () =>
      Object.entries(selectedLists).some(
        ([id, list]) =>
          (list.listLineItemsSize ?? 0) >= MAX_LIST_ITEMS &&
          !selectedAssociatedLists.some((associateId) => associateId === id)
      ),
    [selectedAssociatedLists, selectedLists]
  );

  // 🔵 memo - stores all the unmarked list ids
  const listsToBeRemoved = useMemo(
    () =>
      Object.keys(originalSelectedLists).reduce<string[]>((prev, item) => {
        !Object.keys(selectedLists).includes(item) && prev.push(item);
        return prev;
      }, []),
    [originalSelectedLists, selectedLists]
  );

  /**
   * Callbacks
   */
  // 🟤 Cb - API onCompleted (has to be `function` given by the nature it is called)
  function onApiCompleteListData(autoselect: boolean) {
    return (res: AxiosResponse<GetListsResponse>) => {
      if (!res.data?.lists) {
        return;
      }
      setLists(res.data.lists);
      // Refresh selected lists
      const refreshedSelectedLists = res.data.lists.reduce<SelectedListsMap>(
        (prev, item) => {
          selectedAssociatedLists.some((listId) => listId === item.id) &&
            (prev[item.id] = item);
          return prev;
        },
        {}
      );
      setSelectedLists(refreshedSelectedLists);
      !autoselect && setOriginalSelectedLists(refreshedSelectedLists);
      // Select the 1st item (used for create list)
      if (autoselect) {
        const [newItem] = res.data.lists;
        const mutableMap = { ...selectedLists };
        mutableMap[newItem.id] = newItem;
        setSelectedLists({ ...mutableMap });
        listRef.current?.scrollTo(0);
      }
    };
  }
  // 🟤 Cb - close drawer
  const closeDrawer = () => setAddDrawerOpen(false);
  // 🟤 Cb - Return selected exists
  const getIsSelected = (listId: string) => !isUndefined(selectedLists[listId]);
  // 🟤 Cb - Toggle list item
  const toggleListItem = (list: List) => {
    const mutableMap = { ...selectedLists };
    if (getIsSelected(list.id)) {
      delete mutableMap[list.id];
    } else {
      mutableMap[list.id] = list;
    }
    setSelectedLists(mutableMap);
  };
  // 🟤 Cb - Add to list
  const handleAddToList = async () => {
    if (Object.keys(selectedLists).length) {
      setIsCreatingList(false);
      const res = await addProductToLists();
      res && setSavedSelectedLists(selectedLists);
    }
    handleRemoveProductFromLists();
  };
  // 🟤 Cb - Create list name verify
  const isNameUnique = (name?: Maybe<string>) =>
    !lists.some((l) => l.name === name?.trim());
  // 🟤 Cb - create list call
  const createListSubmit = ({ name }: CreateListForm) => {
    createListCall({ name, products: [] })
      .then(() => {
        const message = t('lists.actionSuccess', {
          name,
          change: 'created'
        });
        setIsCreatingList(false);
        toast({ message, kind: 'success' });
        formik.resetForm();
      })
      .catch(() => {
        const message = t('lists.actionFail', {
          name,
          change: 'created'
        });
        toast({ message, kind: 'error' });
      });
  };

  // 🟤 Cb - Remove products from list
  const handleRemoveProductFromLists = () => {
    if (listsToBeRemoved.length) {
      const listName = filteredLists.find(
        (list) => list.id === listsToBeRemoved[0]
      )?.name;
      removeProductFromLists(
        listsToBeRemoved,
        listName,
        !Object.keys(selectedLists).length
      );
    }
  };

  /**
   * Form
   */
  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t('validation.nameRequired'))
      .matches(acceptedCharPattern, {
        message: t('lists.listCharError', { type: 'name' })
      })
      .max(30, `${t('lists.listCountError', { type: 'name', count: 30 })}`)
      .test('is-name-unique', `${t('lists.listExistsWarning')}`, isNameUnique)
  });
  const formik = useFormik<CreateListForm>({
    initialValues: { name: '' },
    validateOnChange: true,
    enableReinitialize: true,
    validateOnBlur: true,
    validationSchema,
    onSubmit: createListSubmit
  });

  /**
   * Render
   */
  return (
    <div className="w-[416px] h-full p-6">
      <div className="h-full" ref={containerRef}>
        <section ref={topSectionRef}>
          {/* ----- Title ------ */}
          <div className="py-1 pl-1 flex justify-between items-center">
            <h4 className="text-primary-1-100 text-2xl font-medium">
              {t('common.addToList')}
            </h4>
            <IconButton
              onClick={closeDrawer}
              size="large"
              data-testid="list-drawer-close-button"
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </div>
          {/* ----- Limit Warning ------ */}
          <div className="justify-center md:sticky">
            <Collapse in={hasSelectedFullList}>
              <Alert icon={<WarningIcon />} severity="error">
                <Trans
                  i18nKey="lists.listsHardLimitErrorFull"
                  components={baseI18nComponents}
                />
              </Alert>
            </Collapse>
          </div>
          {/* ----- Search input ----- */}
          <div className="mt-8">
            <TextInput
              label={t('lists.searchLists')}
              placeholder={t('lists.searchListsByProduct')}
              value={search}
              onChange={(e) => setSearch(e.currentTarget.value)}
              disabled={createListLoading}
              inputClassName="!pl-12"
              iconStart={<SearchIcon className="text-primary-1-100 ml-2" />}
              onClear={() => setSearch('')}
            />
          </div>
        </section>
        {/* ----- Selections ------ */}
        <div className="relative my-2 flex-1 flex justify-center border border-secondary-3-100 rounded">
          {listsLoading ? (
            <div className="relative w-full" style={{ height: listHeight }}>
              <Loader />
            </div>
          ) : filteredLists.length ? (
            <FixedSizeList<List[]>
              ref={listRef}
              height={Math.min(631, listHeight)}
              width="100%"
              overscanCount={5}
              itemSize={ITEM_SIZE}
              itemCount={filteredLists.length}
              itemData={filteredLists}
              style={{ flex: 1, overflow: 'hidden auto' }}
            >
              {({ style, data, index }) => (
                <div style={style}>
                  <ListDrawerItem
                    list={data[index]}
                    onClick={() => toggleListItem(data[index])}
                    selected={getIsSelected(data[index].id)}
                    disabled={createListLoading || addProductLoading}
                    key={data[index].id}
                  />
                </div>
              )}
            </FixedSizeList>
          ) : (
            <span
              className="py-4 text-center text-secondary-3-100"
              style={{ height: listHeight }}
            >
              {t('common.noResultsFound')}
            </span>
          )}
        </div>
        {/* ----- Action buttons ------ */}
        <section className="flex flex-col h-[240px]">
          <div className="flex-1">
            {isCreatingList ? (
              <form
                className="flex gap-2 items-start pt-6"
                onSubmit={formik.handleSubmit}
                noValidate
              >
                <TextInput
                  name="name"
                  testId="create-list-input"
                  value={formik.values.name}
                  label={t('lists.enterListName')}
                  required
                  placeholder={t('lists.listNamePlaceholder')}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                  message={
                    formik.touched.name && formik.errors.name
                      ? formik.errors.name
                      : ''
                  }
                />
                <Button
                  type="submit"
                  color="lightBlue"
                  className="mt-7 !py-3"
                  data-testid="create-list-submit-button"
                  disabled={!formik.values.name}
                >
                  {t('common.create')}
                </Button>
              </form>
            ) : (
              <Button
                color="lightBlue"
                kind="text"
                className="mb-9"
                iconStart={<AddIcon />}
                data-testid="create-list-button"
                disabled={
                  listsLoading || createListLoading || addProductLoading
                }
                onClick={() => setIsCreatingList(true)}
              >
                {t('lists.createNewList')}
              </Button>
            )}
          </div>
          <Button
            color="primary"
            data-testid="save-button"
            disabled={
              (!Object.keys(selectedLists).length &&
                !listsToBeRemoved.length) ||
              createListLoading ||
              addProductLoading ||
              hasSelectedFullList
            }
            onClick={handleAddToList}
          >
            {t('common.save')}
          </Button>
          <Button
            color="lightBlue"
            data-testid="cancel-button"
            kind="text"
            onClick={closeDrawer}
            disabled={addProductLoading}
          >
            {t('common.cancel')}
          </Button>
        </section>
      </div>
    </div>
  );
}

export default AddToListDrawer;
