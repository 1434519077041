import clsx from 'clsx';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';

import { ListProduct } from 'API/types/products.types';
import { Button, Link, Loader } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { useListsPageContext } from 'pages/Lists/provider/ListsPageProvider';
import ListControls from 'pages/Lists/sub/ListControls';
import ListLineItem from 'pages/Lists/sub/ListLineItem';
import ListLineItemRow from 'pages/Lists/sub/ListLineItemRow';
import { reorder } from 'utils/reorder';

/**
 * Config
 */
const MAX_SCROLLSIZE = 720;

/**
 * Component
 */
function ListMainArea() {
  /**
   * Custom hooks
   */
  const { t } = useTranslation();
  const { isSmallScreen } = useScreenSize();

  /**
   * Config
   */
  const ITEM_SIZE = isSmallScreen ? 312 : 144;

  /**
   * Context
   */
  const {
    filteredListProducts,
    listProducts,
    lists,
    listsLoading,
    fileLoading,
    selectedListLoading,
    sortListLineItem,
    mobileDrawerOpen
  } = useListsPageContext();
  const loading = listsLoading || selectedListLoading;
  const productLength = filteredListProducts.length;

  /**
   * Callbacks
   */
  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    const adjustedItems = reorder(
      listProducts,
      result.source.index,
      result.destination.index
    );
    sortListLineItem(adjustedItems);
  };

  /**
   * Render
   */
  const listContents = () => {
    // 🟢 Loading
    if (loading) {
      return (
        <div className="relative h-[720px]" data-testid="lists-main-loading">
          <Loader />
        </div>
      );
    }
    // 🟢 No lists or line item
    if (!lists.length || !listProducts.length) {
      return (
        <div
          className={clsx(
            'flex flex-col w-full h-[194px] rounded-b border border-x border-t-0 items-center justify-center border-solid border-secondary-3-100',
            { '!rounded !border-t': !lists.length }
          )}
        >
          <p
            className="text-primary-3-100 text-base mb-6 text-center"
            data-testid="lists-search-nolists-subtext"
          >
            {t(!lists.length ? 'lists.noListsSub' : 'lists.listEmptyComplete')}
          </p>
          <Link to="/search/category/">
            <Button data-testid="lists-search-nolists-button">
              {t('common.browseProducts')}
            </Button>
          </Link>
        </div>
      );
    }
    // 🟢 No search results
    if (!filteredListProducts.length) {
      return (
        <div className="flex flex-col w-full h-[130px] items-center justify-center">
          <p
            className="text-secondary-2-100 text-base"
            data-testid="lists-search-no-items-results"
          >
            {t('common.noResultsFound')}
          </p>
        </div>
      );
    }
    // 🟢 With line items
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        {fileLoading && (
          <div
            className="fixed z-10 top-0 left-0 h-[100%] w-[100vw] bg-common-black/40 backdrop-blur-sm"
            data-testid="lists-main-loading"
          >
            <Loader />
          </div>
        )}
        <Droppable
          droppableId="lists-selector"
          mode="virtual"
          renderClone={(provided, snapshot, rubric) => (
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
            >
              <ListLineItem
                isDragged={snapshot.isDragging}
                product={filteredListProducts[rubric.source.index]}
                index={rubric.source.index}
                isLast={rubric.source.index >= productLength - 1}
                testId={`lists-${rubric.source.index}-line-item`}
              />
            </div>
          )}
        >
          {(provided) => (
            <FixedSizeList<ListProduct[]>
              {...provided.droppableProps}
              height={Math.min(MAX_SCROLLSIZE, ITEM_SIZE * productLength)}
              width="100%"
              overscanCount={5}
              itemSize={ITEM_SIZE}
              itemCount={productLength}
              itemData={filteredListProducts}
              style={{ flex: 1, overflow: 'hidden auto', padding: 0 }}
              innerRef={provided.innerRef}
            >
              {ListLineItemRow}
            </FixedSizeList>
          )}
        </Droppable>
      </DragDropContext>
    );
  };
  // 🟢 MAIN RENDER
  return (
    <div
      className={clsx('flex-1 md:pt-2 py-6 min-h-full', {
        hidden: mobileDrawerOpen && isSmallScreen
      })}
    >
      {Boolean(lists.length) && <ListControls />}
      <div
        className={clsx('', {
          'md:px-2': lists.length
        })}
      >
        {listContents()}
      </div>
    </div>
  );
}

export default ListMainArea;
