import { Hidden, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import { DateRange } from 'react-day-picker';
import { useTranslation } from 'react-i18next';

import { OnButtonClick, OnInputChange } from '@reece/global-types';
import { useDateRangeContext } from 'common/DateRange';
import DateRangeInput from 'common/DateRange/DateRangeInput';
import FilterActionButtons from 'common/TablePageLayout/FilterActionButtons';
import { Alert, Label, SelectInput, SelectOption, TextInput } from 'components';
import useScreenSize from 'hooks/useScreenSize';
import { CloseIcon, SearchIcon, WarningIcon } from 'icons';
import {
  Age,
  invoiceFilterOptions,
  invoiceStatus,
  InvoicesStatusButton,
  waterworksInvoiceStatus
} from 'Invoices/util';
import { useSelectedAccountsContext } from 'providers/SelectedAccountsProvider';
import DisplayBlock from 'pages/Orders/components/DisplayBlock';
import {
  ordersSort,
  ordersStatusEclipse,
  ordersStatusMincron
} from 'pages/Orders/util/lib';

/**
 * Config
 */
const waterworksInvoiceStatusOptions: SelectOption[] =
  waterworksInvoiceStatus.map((value) => ({ label: value, value }));
const agingOptions: SelectOption[] = Object.values(invoiceFilterOptions).map(
  (value) => ({ label: value, value })
);
const eclipseOrdersStatusOptions: SelectOption[] = ordersStatusEclipse.map(
  (value) => ({ label: value, value })
);
const waterworksOrdersStatusOptions: SelectOption[] = ordersStatusMincron.map(
  (value) => ({ label: value.label, value: value.id })
);
const ordersSortOptions: SelectOption[] = ordersSort.map((value) => ({
  label: value.label,
  value: value.id
}));

/**
 * Types
 */
export type SearchCardProps = {
  applied?: boolean;
  displayCount?: string;
  filterValue?: string;
  handleFilterChange?: (filterValue: Age) => void;
  handleSortChange?: (
    sortBy: { id: string; direction: boolean },
    currentPage: string
  ) => void;
  invoicesAgingFilterValue?: string;
  isContract?: boolean;
  isInvoices?: boolean;
  newOrdersPage?: boolean;
  onReset: () => void;
  onExportClicked?: () => void;
  onViewResultsClicked: () => void;
  ordersPage?: boolean;
  placeholder?: string;
  range?: DateRange;
  resultsCount?: number;
  search: string;
  selectFilter?: string;
  selectFilterDefault?: string;
  selectFilterLabel?: string;
  setApplied?: (applied: boolean) => void;
  setDisplayCount?: (displayCount: string) => void;
  setFilterValue?: (filterValue: string) => void;
  setInitialState?: (initialState: boolean) => void;
  setInvoicesAgingFilterValue?: (invoiceAgingFilter: string) => void;
  setReset?: (reset: boolean) => void;
  setSelectFilter?: (selectFilter: string) => void;
  setSearch: (search: string) => void;
  showSelectFilter?: boolean;
  sortBy?: {
    id: string;
    direction: boolean;
  };
  testId?: string;
  warningMessage?: string;
  callPrintOrders?: () => void;
  printOrdersLoading?: boolean;
};

/**
 * Component
 */
function SearchCard(props: SearchCardProps) {
  /**
   * Custom hooks
   */
  const { isSmallScreen } = useScreenSize();
  const { t } = useTranslation();

  /**
   * Context
   */
  const dateRangeContext = useDateRangeContext();
  const { isMincron } = useSelectedAccountsContext();

  /**
   * Callbacks
   */
  // 🟤 Cb - reset
  const onResetClicked = () => {
    props.setFilterValue?.('All');
    props.setInvoicesAgingFilterValue?.('All');
    props.setApplied?.(false);
    props.setInitialState?.(true);
    props.setReset?.(true);
    props.onReset();
  };
  // 🟤 Cb - search
  const handleSearch = ({ target }: OnInputChange) => {
    props.setSearch(target.value);
    props.setApplied?.(!target.value);
    !target.value.length && props.newOrdersPage && onClearOrdersSearch();
  };
  // 🟤 Cb - invoice status
  const handleInvoiceStatus = (_: OnButtonClick, status: string) => {
    if (status === 'All' || status === 'Closed') {
      props.setInvoicesAgingFilterValue?.('All');
    }
    handleFilters(status);
    props.setApplied?.(false);
  };
  // 🟤 Cb - filters
  const handleFilters = (status: string) => {
    props.setFilterValue?.(status);
    props.setSelectFilter?.(status);
    props.setApplied?.(false);
  };
  // 🟤 Cb - view results
  const onViewResults = () => {
    props.setInitialState?.(false);
    props.setApplied?.(true);
    props.onViewResultsClicked();
  };

  // 🟤 Cb - clear orders search
  const onClearOrdersSearch = () => {
    props.setSearch('');
    props.onReset();
  };

  // 🟤 Cb - handle export orders
  const handleExportOrders = () => {
    props.onExportClicked?.();
  };

  // 🟤 Cb - handle orders sort value
  const ordersSortValue = (sortOrder?: { id: string; direction: boolean }) =>
    !sortOrder?.direction ? '!' + sortOrder?.id : sortOrder?.id;

  // 🟤 Cb - handle sort change
  const handleSortChange = (id: string) => {
    const sort = {
      id: id.replace('!', ''),
      direction: !id.includes('!')
    };
    props?.handleSortChange?.(sort, '1');
  };

  /**
   * Render
   */
  return (
    <div className="w-full flex gap-2 content-end items-start md:flex-col">
      {/* Search By */}
      <div
        className={clsx('md:w-full md:max-w-full md:basis-full flex-grow-0', {
          'max-w-1/4 basis-1/4': !props?.ordersPage,
          'max-w-1/3 basis-1/3': props?.ordersPage
        })}
      >
        <TextInput
          autoFocus
          label={
            !(props.newOrdersPage && isSmallScreen)
              ? props?.newOrdersPage
                ? t('common.search')
                : t('common.searchBy')
              : undefined
          }
          placeholder={props.placeholder || ''}
          value={props.search}
          onChange={handleSearch}
          onKeyDown={
            props.newOrdersPage
              ? (e) => e.key === 'Enter' && onViewResults()
              : undefined
          }
          testId={props.testId ?? ''}
          className="z-0"
          iconStart={
            props.newOrdersPage ? (
              <SearchIcon onClick={onViewResults} className="cursor-pointer" />
            ) : undefined
          }
          iconEndClassName="!text-primary-3-100"
          iconEnd={
            props.newOrdersPage && Boolean(props.search) ? (
              <CloseIcon
                onClick={onClearOrdersSearch}
                className="cursor-pointer !h-[15px]"
                data-testid="search-clear-icon"
              />
            ) : undefined
          }
          iconStartClassName="text-primary-1-100"
        />
      </div>
      {/* Status & Aging (Invoices) */}
      {props.isInvoices && props.showSelectFilter && (
        <>
          <div className="w-auto md:w-full mb-[7px]">
            {isMincron ? (
              // Status - Waterworks
              <SelectInput
                label={t('common.status')}
                value={props.filterValue}
                listKey="searchcard-waterworks-status"
                onChange={handleFilters}
                testId={`${props.testId}-ww-status-filter`}
                options={waterworksInvoiceStatusOptions}
              />
            ) : (
              // Status - Plumbing/HVAC
              <>
                <Label label={t('common.status')} />
                <ToggleButtonGroup
                  fullWidth
                  value={props.filterValue}
                  data-testid={`${props.testId}-status-filter`}
                  onChange={handleInvoiceStatus}
                  exclusive
                >
                  {invoiceStatus.map((option) => (
                    <InvoicesStatusButton
                      disableRipple
                      value={option}
                      key={option}
                    >
                      {option}
                    </InvoicesStatusButton>
                  ))}
                </ToggleButtonGroup>
              </>
            )}
          </div>
          {/* Aging */}
          <div className="max-w-full flex-grow-1 basis-0 md:w-full mb-[7px]">
            <SelectInput
              label={props.selectFilterLabel}
              name={props.selectFilterLabel}
              value={props.invoicesAgingFilterValue}
              onChange={(age) => props.handleFilterChange?.(age as Age)}
              options={agingOptions}
              listKey="searchcard-age"
              testId={`${props.testId}-select-filter`}
            />
          </div>
        </>
      )}
      {/* Date Range */}
      <div className="max-w-1/4 flex-grow-0 basis-1/4 lg:max-w-1/3 lg:basis-1/3 md:!w-full md:!max-w-full">
        <DateRangeInput
          invoiceStatus={props.filterValue}
          label={props.isContract ? t('contracts.dateRange') : undefined}
          singleField={props?.newOrdersPage}
          testId={props.testId}
        />
        <div className="w-full">
          {props.warningMessage === 'error' && (
            <p
              className="h-[17px] text-xs font-normal text-support-1-100"
              data-testid={`${props.testId}-range-error`}
            >
              {t('invoices.rangeError')}
            </p>
          )}
          {/* Warning (Invoices and others) */}
          {props.warningMessage === 'warning' && !props.ordersPage && (
            <div className="flex flex-row items-center p-2 gap-2 bg-secondary-1-10 border border-solid border-secondary-1-100 rounded">
              <WarningIcon className="text-secondary-1-100" />
              <div className="flex-1">
                <p
                  className="flex items-center text-xs font-medium text-common-black"
                  data-testid={`${props.testId}-range-warning`}
                >
                  {t('invoices.rangeWarning')}
                </p>
              </div>
            </div>
          )}
          {/* Warning (Orders) */}
          {props.warningMessage === 'warning' && props.ordersPage && (
            <div className="w-full">
              <Alert
                security="warning"
                className="!mt-2 !px-2 !py-1.5"
                data-testid={`${props.testId}-warnings-alert`}
              >
                <b>{t('common.warning')}:</b>{' '}
                {t('common.thirtyDayWarningMessage')}
              </Alert>
            </div>
          )}
        </div>
      </div>
      {/* Orders status */}
      {props.newOrdersPage && (
        <div className="grid w-auto md:w-full md:!grid-cols-2 md:gap-4">
          <div className="w-auto md:w-full mb-[7px] min-w-[175px] md:min-w-min">
            <SelectInput
              label={t('common.orderStatus')}
              value={props.filterValue}
              listKey="searchcard-orders-status"
              onChange={handleFilters}
              testId={`${props.testId}-orders-status-filter`}
              options={
                isMincron
                  ? waterworksOrdersStatusOptions
                  : eclipseOrdersStatusOptions
              }
            />
          </div>
          <Hidden mdUp>
            <div className="w-auto md:w-full mb-[7px] min-w-[125px] md:min-w-min">
              <SelectInput
                label="Sort"
                value={ordersSortValue(props.sortBy)}
                listKey="searchcard-orders-status"
                onChange={handleSortChange}
                testId={`${props.testId}-orders-sort-filter`}
                options={ordersSortOptions}
              />
            </div>
          </Hidden>
        </div>
      )}
      {/* Buttons */}
      {!props.newOrdersPage && (
        <FilterActionButtons
          warningMessage={props.warningMessage}
          range={props.range}
          applied={props.applied}
          invoiceStatus={props.filterValue}
          dirty={Boolean(
            dateRangeContext?.value?.from ||
              dateRangeContext?.value?.to ||
              props.search.length > 0 ||
              props.showSelectFilter
          )}
          hideApplyOnMobile={false}
          resultsCount={
            props.isInvoices && props.showSelectFilter
              ? undefined
              : props.resultsCount
          }
          onViewResults={onViewResults}
          onReset={onResetClicked}
        />
      )}
      {/* Display Block */}
      {props.newOrdersPage && !isSmallScreen && (
        <DisplayBlock
          showPrint
          displayCount={props.displayCount}
          setDisplayCount={props.setDisplayCount}
          onExportClicked={handleExportOrders}
          hasResults={Boolean(props.resultsCount)}
          callPrintOrders={props.callPrintOrders}
          printOrdersLoading={props.printOrdersLoading}
        />
      )}
    </div>
  );
}

export default SearchCard;
